import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Markdown from "react-markdown"

const StMarkdown = styled(Markdown)`
  p {
    margin-bottom: ${p => p.theme.spacing.s};
    ${p => p.theme.textStyle.body};
  }
  h2 {
    ${p => p.theme.textStyle.title05};
    margin-top: ${p => p.theme.spacing.xxl};
    margin-bottom: ${p => p.theme.spacing.s};
  }
  ul {
    width: ${p => (p.isPortraitTablet ? `80%` : "100%")};
    margin-bottom: ${p => (p.isPortraitTablet ? p.theme.spacing.l : p.theme.spacing.s)};
    li {
      display: flex;
      margin-bottom: ${p => p.theme.spacing.s};
      margin-left: ${p => (p.isPortraitTablet ? p.theme.spacing.l : 0)};
      ${p => p.theme.textStyle.body};

      &:before {
        content: "";
        display: inline-table;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${p => p.theme.color.icon02};
        margin-right: ${p => p.theme.spacing.xs};
        margin-top: 11px;
      }
    }
  }
  ol {
    width: ${p => (p.isPortraitTablet ? `80%` : "100%")};
    margin-bottom: ${p => (p.isPortraitTablet ? p.theme.spacing.l : p.theme.spacing.s)};
    list-style-type: decimal;

    li {
      margin-bottom: ${p => p.theme.spacing.s};
      margin-left: ${p => (p.isPortraitTablet ? p.theme.spacing.l : 0)};
      ${p => p.theme.textStyle.body};
    }
  }
`

const StyledMarkdown = ({ source }) => {
  const breakpoints = useBreakpoint()
  return <StMarkdown source={source} escapeHtml={false} isPortraitTablet={breakpoints.m} />
}

export default StyledMarkdown
